import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import StandardPage from "../components/standard-page"

export const data = graphql`
  query($slug: String!, $imgDir: String!) {
    images: allFile(filter: {relativeDirectory: {eq: $imgDir}}, sort: {fields: name, order: ASC}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    content: markdownRemark(frontmatter: { path: { eq: $slug } }) {
      ...StandardMarkdownFragment
    }
  }
`;

export default ({ data }) => {
  const { content, images } = data;
  return (
    <Layout>
      <SEO title={content.frontmatter.title} />
      <StandardPage 
        title={content.frontmatter.title} 
        images={images} 
        html={content.html} 
      />
    </Layout>
  )
}